import React from "react";

import { Container } from "reusecore/Layout";
import SectionTitle from "reusecore/SectionTitle";
import Button from "reusecore/Button";

import ContactWrapper from "./contact.style";

const Contact = () => {
    return (
        <ContactWrapper id="contact">
            <SectionTitle className="section-title" UniWidth="55%">
                {/* <h4>Contact Us</h4> */}
                <h2>
                    <span>Let us know your thought</span> and we will get back to you.
                </h2>
            </SectionTitle>
            <Container>
                <form name="contactform" method="post" action="#">
                    <input type="text" placeholder="Title" /><br/>
                    <textarea rows={10} placeholder="Inquiry"></textarea><br/>
                    <input type="email" placeholder="Enter your Email" /><br/>
                    <Button>Send Now</Button>  
                </form>
            </Container>
        </ContactWrapper>
    );
};

export default Contact;
